import Auth from '@/modules/Auth';

import config from '@/utility/config';
import { getQueryStringFromObject } from '@/utility/routesHelper';

import adminProtectedAxiosRequest from '../helpers/adminProtectedAxiosRequest';
import authorizedRequest from '../helpers/authorizedRequest';
import publicAxiosRequest from '../helpers/publicAxiosRequest';

const { communityApiBasePath } = config;

export const getAnnouncements = async (communityId, params) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/announcements`;
    const authToken = Auth.getUserToken();
    let res;
    if (!authToken) {
      return await publicAxiosRequest.get(path, { params });
    }
    res = await adminProtectedAxiosRequest.get(path, { params });
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const getPagedAnnouncements = async (communityId, params) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/announcements?${getQueryStringFromObject(
      {
        queryParams: params
      }
    )}`;

    const { data, error } = await authorizedRequest(path, {
      method: 'GET'
    });

    if (error) {
      return { error };
    }

    return { data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const getPinnedAnnouncements = async (communityId, search) => {
  try {
    let path = `${communityApiBasePath}/api/v1/communities/${communityId}/pinned-announcements`;
    if (search) {
      path += `?searchQuery=${search}`;
    }
    return await authorizedRequest(path, {
      method: 'GET'
    });
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const getAnnouncement = async (communityId, announcementId) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/announcement/${announcementId}`;
    const res = await adminProtectedAxiosRequest.get(path);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

/**
 * @typedef {Object} getAnnouncementBySlug
 * @property {string} communityLink - The communityLink
 * @property {string} postSlug - The postSlug
 * @returns {Object} res
 * @returns {Object} res.data - The data
 * @returns {string} res.error - The error
 */
export const getAnnouncementBySlug = async (communityLink, postSlug) => {
  try {
    const communitySlugWithoutSlash = communityLink.replace(/\//g, '');
    const apiPath = `${communityApiBasePath}/api/v1/posts/${communitySlugWithoutSlash}/${postSlug}`;
    const authToken = Auth.getUserToken();
    if (!authToken) {
      return await publicAxiosRequest.get(apiPath);
    }
    return await adminProtectedAxiosRequest(apiPath);
  } catch (e) {
    return { data: null, error: e };
  }
};

export const createAnnouncement = async (payload) => {
  try {
    const { communityId } = payload;
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/announcement`;
    const res = await adminProtectedAxiosRequest.post(path, payload);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const updateAnnouncement = async (payload) => {
  try {
    const { communityId } = payload;
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/announcement`;
    const res = await adminProtectedAxiosRequest.patch(path, payload);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const deleteAnnouncement = async (communityId, postId) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/announcement/${postId}`;
    const res = await adminProtectedAxiosRequest.delete(path);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const revalidatePostPublicPage = async (community, post) => {
  try {
    const communityLink = community?.link;
    const postSlug = post?.slug;
    const payload = {
      slug: `${communityLink}${postSlug}`
    };
    const path = `${config.apiPaymentBasePath}/api/v1/revalidate-fe-path`;
    const res = await adminProtectedAxiosRequest.post(path, payload);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const getPostsEntities = async ({
  communityId,
  pageNo,
  pageSize,
  search
}) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/products/announcement`;
    const params = {
      pageNo,
      pageSize,
      search
    };
    const res = await adminProtectedAxiosRequest.get(path, { params });
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const getPendingAnnouncements = async (communityId, params) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/announcements?${getQueryStringFromObject(
      {
        queryParams: params
      }
    )}`;

    const { data, error } = await authorizedRequest(path, {
      method: 'GET'
    });

    if (error) {
      return { error };
    }

    return { data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};

export const updatPendingAnnouncement = async (communityId, postId) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/announcement/${postId}/approve`;
    const res = await adminProtectedAxiosRequest.patch(path);
    return { data: res.data, error: null };
  } catch (error) {
    return { error: error?.response?.data?.message ?? error.message };
  }
};
