import {
  trackGAEvent,
  trackGAEventWithCommunityAndUser
} from '@/utility/analytics';

const TRACK_POST_LANDING_PAGE_VIEWED = 'post_landing_page_viewed';
const TRACK_POST_LANDING_PAGE_PUBLIC_VIEWED =
  'post_landing_page_public_post_viewed';
const TRACK_POST_LANDING_PAGE_MEMBERONLY_VIEWED =
  'post_landing_page_memberonly_post_viewed';

const TRACK_POST_LANDING_PAGE_UNLOCK_POST_CLICK =
  'post_landing_page_unlock_post_clicked';

const TRACK_POST_LANDING_PAGE_BECOM_MEM_CLICK =
  'post_landing_page_become_a_member_clicked';

const TRACK_POST_LANDING_PAGE_SHARE_BTN_CLICK =
  'post_landing_page_share_btn_clicked';

const TRACK_COMM_LANDING_PAGE_SEE_MORE_POSTS_CLICK =
  'comm_landing_page_post_see_more_clicked';

const TRACK_FEED_POST_VIEWED = 'feed_post_viewed';

const TRACK_CMP_SHARE_BTN_CLICK = 'post_landing_page_share_btn_clicked';

export const trackPostLandingPageViewed = ({ isPublic }) => {
  trackGAEvent(TRACK_POST_LANDING_PAGE_VIEWED);

  if (isPublic) {
    trackGAEvent(TRACK_POST_LANDING_PAGE_PUBLIC_VIEWED);
  } else {
    trackGAEvent(TRACK_POST_LANDING_PAGE_MEMBERONLY_VIEWED);
  }
};

export const trackPostLandingPageUnlockPostClick = () => {
  trackGAEvent(TRACK_POST_LANDING_PAGE_UNLOCK_POST_CLICK);
};

export const trackCommLandingPageUnlockPostClick = ({
  communityId,
  postId
}) => {
  trackGAEvent(TRACK_POST_LANDING_PAGE_UNLOCK_POST_CLICK, {
    communityId,
    postId
  });
};

export const trackPostLandingPageBecomeAMemberClick = () => {
  trackGAEvent(TRACK_POST_LANDING_PAGE_BECOM_MEM_CLICK);
};

export const trackPostLandingPageShareBtnClick = () => {
  trackGAEvent(TRACK_POST_LANDING_PAGE_SHARE_BTN_CLICK);
};

export const trackCMPSharePostBtnClicked = () => {
  trackGAEventWithCommunityAndUser(TRACK_CMP_SHARE_BTN_CLICK);
};

export const trackCommPublicPagePostSeeMoreClick = () => {
  trackGAEvent(TRACK_COMM_LANDING_PAGE_SEE_MORE_POSTS_CLICK);
};

export const trackPostViewed = (postId, communityId) => {
  trackGAEvent(TRACK_FEED_POST_VIEWED, {
    communityId,
    productType: 'POST',
    productId: postId
  });
};
