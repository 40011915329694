import { useMemo } from 'react';

import { t } from '@/utility/localization';
import {
  ENTITY_TYPE_SLUG,
  getEntityPublicPageUrl
} from '@/utility/routesHelper';

import NextImage from '@/components/common/NextImage';
import Icon from '@/components/npl/Icon';

export const EntityPostCard = ({ entity, onRemove, communityLink }) => {
  const { type, title, thumbnail, cover, bannerImg, slug, resourceSlug } =
    entity || {};

  const imgData = {
    // Could be either of these depending on Entity type
    src: thumbnail || cover || bannerImg,
    width: 80,
    height: 45,
    quality: 50,
    objectFit: 'cover'
  };

  const entityUrl = useMemo(() => {
    const entityTypeSlug = ENTITY_TYPE_SLUG[type];
    return getEntityPublicPageUrl({
      communitySlug: communityLink,
      entityType: entityTypeSlug,
      entitySlug: slug || resourceSlug
    });
  }, [communityLink, slug, type]);

  if (!imgData?.src || !title) {
    return null;
  }

  return (
    <a
      target="_blank"
      href={entityUrl}
      className={`p-8 rounded-12 flex flex-row items-center gap-12 justify-between h-[62px] relative overflow-hidden`}
      rel="noreferrer">
      <div className="absolute left-0 top-0 w-full">
        <img
          src={imgData?.src}
          alt="banner"
          className="w-full h-full blur-3xl object-cover opacity-50"
        />
      </div>
      <div className="flex flex-row items-center gap-12">
        {imgData?.src && (
          <div className="min-w-[80px] rounded-8 overflow-hidden aspect-video">
            <NextImage mobileImgProps={imgData} />
          </div>
        )}
        <div className="flex flex-col justify-center">
          <p className="mb-2 text-label-xs text-npl-text-secondary-on-light">
            {t(type)}
          </p>
          <p className="text-label-md font-medium text-npl-text-primary-on-light">
            {title}
          </p>
        </div>
      </div>
      {onRemove && (
        <div
          onClick={(e) => {
            e?.preventDefault();
            e?.stopPropagation();
            onRemove();
          }}
          tabIndex={0}
          role="button"
          className="cursor-pointer z-10">
          <Icon
            name="x-circle-filled"
            fill="#3D3D3D"
            fillOpacity={0.5}
            width={20}
            height={20}
          />
        </div>
      )}
    </a>
  );
};
