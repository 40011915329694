import config from '@/utility/config';

import { getAxiosErrorMsg } from './helpers';
import publicAxiosRequest from './helpers/publicAxiosRequest';

interface PayloadTypes {
  entityid: string;
  entitytype: string;
  env: string;
  m_time: Date;
  sessionid: string;
  user_id: string;
}

const { analyticsApiBasePath } = config;

export const trackFeedCardView = async (payload: PayloadTypes) => {
  //if the analyticsApiBasePath is not defined, return an empty object
  //we don't need to track these metrics locally
  if (!analyticsApiBasePath) {
    return { data: {}, error: null };
  }

  const apiPath = `${analyticsApiBasePath}/data`;
  const { data } = await publicAxiosRequest.post(apiPath, payload);

  const error = data?.error;

  if (error) {
    console.error(getAxiosErrorMsg(error));
    return { error };
  }

  return { data, error: null };
};
