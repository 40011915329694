import Cookies from 'js-cookie';
import { useCallback, useEffect, useMemo } from 'react';

import { importNanoid } from '@/utility/helpers';

import { useAuthContext } from '@/contexts/AuthContext';

export const VIEWING_SESSION_ID_KEY = 'userSessionId';
const cookieExpirationTime = 30; //in minutes

const useSessionId = () => {
  const { user } = useAuthContext();

  const sessionId = useMemo(() => {
    if (!user) {
      let id = Cookies.get(VIEWING_SESSION_ID_KEY);
      if (!id) {
        const { nanoid } = importNanoid();
        id = nanoid();
      }
      return id;
    }
    return user?._id;
  }, [user]);

  const setCookie = useCallback(() => {
    if (sessionId) {
      const expirationTime = new Date(
        new Date().getTime() + cookieExpirationTime * 60 * 1000
      );
      Cookies.set(VIEWING_SESSION_ID_KEY, sessionId, {
        expires: expirationTime
      });
    }
  }, [sessionId]);

  useEffect(() => {
    setCookie();
  }, [user]);
};

export default useSessionId;
